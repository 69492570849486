footer {
	text-align:center;
	font-size:80%;
	margin: 3em 0;
}

#twitter-follow-button {
    padding: 3px 10px;
    background-color: #1b95e0;
    color: #fff;
    border-radius: 3px;
    font-size: 11px;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
}

#twitter-follow-button svg {
	font-size:115%;
	margin-right: 0.4em;
}

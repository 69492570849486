
.home-upper {
	display: flex;
	align-items: center;
}

.home-video-container {
	margin-top: 1.5em;
	margin-right: 1em;
	min-width: 60%;
}

.home video {
	width: 100%;
	height: 100%;
	box-shadow: 1px 1px 0.6em #CCC, 1px 1px 0.05em #FFF;
}

.home .i-wanna {
	font-size:160%;
	margin-bottom: 0;
}

.home .splash-boxnav {
}

.home .splash-boxnav-item {
}

.home .splash-boxnav-item .item {
	min-height: 4em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	font-size: 160%;
    font-weight: 300;
    color: white;
    text-shadow: 1px 1px 1px #999;
}

.home .splash-boxnav-item .item:not(.item-nolink):hover {
	text-shadow: 1px 1px 1px #CCC;
}

.home .splash-boxnav-item .item-nolink {
	cursor: default;
	box-shadow: inherit;
	overflow: hidden;
}

.home .splash-boxnav-item .item-nolink:after {
	content: "Coming Soon";
    position: absolute;
    top: 17.5%;
    right: -12.5%;
    background: rgba(255, 255, 255, 0.25);
    font-size: 14px;
    text-shadow: none;
    font-weight: 700;
    transform: rotate(45deg);
    padding: 0 10%;
}

.home .splash-boxnav .item-0 {
	background: linear-gradient(82deg, #00bac0, #00bac080);
}

.home .splash-boxnav .item-2 {
	background: linear-gradient(82deg, #ab9dec, #ab9dec80);
}

.home .splash-boxnav .item-3 {
	background: linear-gradient(82deg, #ff86c0, #ff86c080);
}

.home .splash-boxnav .item-1 {
	background: linear-gradient(82deg, #60ade0, #60ade080);
}

.this-is-v1 {
    border: 3px solid #ff86c0;
    padding: 1em;
    background-color: #ffeef6;
    border-radius: 0.5em;
    color: #cd0062;
}

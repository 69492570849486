.tour-helper {
}

.tour-helper [data-tour-elem="controls"] {
	justify-content: space-between;
}

.tour-helper button[data-tour-elem="dot"],
.tour-helper button[data-tour-elem="left-arrow"],
.tour-helper button[data-tour-elem="right-arrow"]
{
	color: #666;
	transform: scale(2);
}

.tour-helper button[data-tour-elem="dot"] {
	font-size:40%;
}

.tour-helper button[data-tour-elem="dot"]:hover {
	background-color: #CCC !important;
}

.tour-helper button:hover {
	color: inherit !important;
	background-color: inherit !important;
}

.tour-helper button:disabled {
	opacity:1;
}

.tour-demo {
	display: inline-block;
	font-weight: bold;
}

.tour-demo-edit, .tour-demo-available {
	color: #65b965;
}

.tour-demo-unavailable {
	color: #666;
}

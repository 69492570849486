.gameline {
    display: grid;
    grid-template-columns: 2em 9% 9% 9% 9% 2em 1fr;
	grid-gap: 1%;
	align-items:center;
	margin:1em 0;
}

.gameline > div {
    display: flex;
	flex-direction: column;
    justify-content: center;
}

.gameline > div:nth-child(1) {
	text-align:center;
	padding:4px;
}

.gameline .cell-position {
	min-width: auto;
	cursor: initial;
}

.gameline .cell-position-na {
    opacity: 0;
    visibility: hidden;
}

.gameline .player-game {
	font-size:85%;
}

a.gameline {
	text-decoration:none;
	color:inherit;
}

.gameline .orange-image {
	max-width:75%;
}

.gameline-future {
	opacity:0.6;
}

.gameline-future .player-game {
    font-style: italic;
}

.share-area {
	text-align:center;
}

.share-url {
    background: #eee;
    display: inline-block;
    padding: 0.2em 0.5em;
    border-radius: 3px;
}

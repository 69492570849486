.news-entry {
	display: grid;
    grid-template-columns: auto 1fr;
	margin: 1em 0;
}

.news-date {
	color: #999;
	margin-right: 1em;
}

.orangeman {
	box-shadow: 1px 1px 3px inset #CCC;
	font-size:90%;
	min-width: 30%;
	padding: 0.2em;
	display: inline-flex;
	align-items: center;
}

.orangeman .orange-image {
	height:2.5em;
	margin-right:1em;
}

.clear-orangeman {
	margin-left:auto;
	cursor: pointer;
	color:#999;
	padding:0.5em;
}

.clear-orangeman:hover {
	color:#000;
}

.roster-locked .orangeman {
	box-shadow:none;
}

.roster-locked .clear-orangeman {
	display:none;
}

.select-orangeman {
	flex-grow: 1;
}

.select-orangeman .MuiSelect-icon,
.select-orangeman .MuiInput-underline:before,
.select-orangeman .MuiInput-underline:after
{
	display:none;
}

.orangeman .MuiInputBase-root.Mui-disabled {
	color: inherit;
}

@media only print {
	.orangeman {
		box-shadow:none;
	}

	.clear-orangeman {
		display: none;
	}
}

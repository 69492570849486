.grid {
    display: flex;
	justify-content: space-around;
}

.column-players {
	flex-grow:1;
}

.roster-altboard .undergrid .column-players {
	flex-grow:inherit;
}

.cell {
	height:2.3em;
	line-height:1em;
	text-align:center;
	display:flex;
	justify-content: center;
	align-items: center;
	position:relative;
	user-select: none;
	flex-wrap:wrap;
	overflow:hidden;
}

@media only screen {
	.roster-altboard .cell-position {
		border: 1px solid transparent;
	}
	.roster-altboard #board .column-players .cell-position:not(.cell-position--) {
		border-color: #999;
	}
}

.roster-altboard #board .column-players .cell-position {
	background-color:transparent;
	color: black;
	font-weight: 600;
	padding: 0 0.2em;
}

.cell-header {
	font-weight:bold;
}

.cell-position {
	font-family:"Heebo";
	box-shadow:1px 1px 3px #CCC;
	min-width:12vw;
	cursor:grab;
	color:white;
	font-weight:900;
	background-color:#65b965;
}

.roster-altboard .cell-position {
	min-width: 18vw;
	max-width: 19vw;
}

.roster-altboard .column-players .cell-position {
	min-width: auto;
}

.roster-altboard .column-positions .cell-position {
	font-weight: normal;
	transition: color 1s ease-out, background-color 1s ease-out;
}

.roster-altboard .column-positions:not(.column-alt-positions) .cell-position-- span {
	color: #666;
}

.cell-player, .column-positions .posed-element, .fixed-element {
	padding: 0.4em;
}

.fixed-element-unavailable.fixed-element-- {
    margin: 0;
    padding: 0;
}

.cell-player {
	cursor: grab;
	box-shadow: 1px 1px 3px #999;
	background-color: #FAFAFA;
	overflow: auto;
}

.cell-player-unavailable {
	opacity:0.3;
}

.fixed-element .cell-player-unavailable {
	display:none;
}

.cell-player svg {
	padding:0.4em;
	border:1px solid transparent;
	border-radius:1em;
}

.cell-player svg:hover {
	border-color:#DDD;
	background-color:white;
}

.cell-player .tick {
	color: #65b965;
}

.fixed-element .cell-player, .fixed-element .cell-position {
	padding:0;
	box-shadow:none;
	cursor:inherit;
}

.fixed-element .cell-position.cell-position-unavailable, .roster-altboard .cell-position.cell-position-unavailable {
	background: linear-gradient(to bottom right, #ccc calc(50% - 1px), #ffffff, #ccc calc(50% + 1px) );
	font-weight: 400;
}

.roster-altboard .cell-position-unavailable .netStatValue {
	display: none;
}

.cell-void {
	background-color:transparent;
	color: black;
}

.column-preferences .position-preference {
	padding-left: 0.6vw;
	padding-right: 0.6vw;
}

.column-preferences .cell-position {
	cursor: pointer;
}

.column-preferences .cell-position-normal {
	min-width: 4em;
}

.roster-locked .column-preferences .cell-position-normal {
	box-shadow: none;
	cursor: default;
}

.roster-unlocked .column-preferences .cell-position-- {
    box-shadow: 0 0 12px #CCC inset;
}

.posed-element:active {
	position:relative;
	z-index:10 !important;
}

.cell-position-GS {
	background-color:#65b965;
}
.cell-position-GA {
	background-color:#00c097;
}
.cell-position-WA {
	background-color:#00bac0;
}
.cell-position-C {
	background-color:#60ade0;
}
.cell-position-WD {
	background-color:#ab9dec;
}
.cell-position-GD {
	background-color:#e38edf;
}
.cell-position-GK {
	background-color:#ff86c0;
}
.cell-position-- {
	background-color:#EEEEEE;
}

.roster-locked .cell-locked {
	display:none !important;
}

.iconbox {
	position:absolute;
	top:0;
}

.cell-position .iconbox {
	left:0.2vw;
}

.cell-player .iconbox {
	left: 0.2vw;
	height: 100%;
	display: flex;
	align-items: center;
}

.cell-player .iconbox-right {
	left: unset;
	right: 0.2vw;
	color:#65b964;
}

.fixed-element .cell-player .iconbox {
	display: none;
}

.cell-player .iconbox-hitbox {
	cursor: pointer;
	line-height: 0;
}

.cell-position-nulled {
	background-color: #BBB;
}

.fixed-element .cell-position--.cell-position-unavailable {
	display:none;
}

.cell-player .add-item-field {
	text-align: center;
	font-size: 100%;
	margin: 0 !important;
	padding: 4px !important;
}

.settings {
	max-width:500px;
	padding:0.5em 2em;
	border-radius:3px;
	margin:1em auto;
	background-color:#F6F6F6;
}

.settings h3 {
	margin-top: 0.5em;
	display: flex;
	align-items: center;
}

.settings h3 svg.text-icon {
	position: relative;
	top: 0.15em;
	margin-left: 0.2em;
}

.settings .settings-preset-container {
	margin-left: auto;
}

.settings p {
	margin-bottom:0;
}

.settings svg.tooltip-icon {
	font-size: 70%;
	color: green;
	margin: 0 0.5em;
}

.settings .settings-netstats-toggle {
	text-align: right;
	width: 100%;
}

#root .MuiFormLabel-root.Mui-focused:not(.Mui-error) {
	color: green;
}

#root .MuiInput-underline:after {
	border-bottom: 2px solid #52af76;
}

.controlbox, .add-player-buttons {
	display:flex;
}

.controlbox {
	margin: 0.5em 0;
}

.add-player-buttons {
	margin:2em 0 3em 0;
}

.controlbox button {
	min-width: 8%;
}

button.button-go {
	min-width: 8em;
}

.controlbox .button-right {
	margin-left:auto;
}

.button-preferences {
	position:relative;
}

.button-preferences.have-set-positions:after {
    content: "•";
    position: absolute;
    top: 0;
    right: 25%;
}

.settings .setting svg.tooltip-icon {
	margin-right: 2em;
}

.settings-warning {
    color: #e4a228;
    font-size: 90%;
	display: inline-block;
}

/* stupid override */
.setting .MuiSlider-thumb.Mui-disabled {
    width: 24px;
    height: 24px;
    margin-top: -8px;
    margin-left: -12px;
}

.button-go-highlight {
	background: #65b964;
	color: white;
	animation: pulse 4s infinite;
	animation-delay: 2s;
	z-index: 2;
}

@keyframes pulse {
	0% {		box-shadow: 0 0 0 0		rgba(101, 185, 100, 0.7); }
	40% {		box-shadow: 0 0 0 20px	rgba(101, 185, 100, 0); }
	45%, 100% {	box-shadow: 0 0 0 0		rgba(101, 185, 100, 0); }
}

.button-save:enabled {
	color:#000;
	background-color: #ffd6ff;
}

#root .button-save:not(:disabled):hover {
	background-color:#e38edf;
}

#root .button-go-highlight:not(:disabled):hover {
	background-color:#3dce00;
}

.button-delete {
	text-align:center;
	margin-top:2em;
	margin-bottom:2em;
}

.add-player-buttons .button-delete {
	margin: 0 1% 0 5%;
}

.underneath-grid {
    display: flex;
	flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
	margin:1em 0;
}

.controlbox button, .add-player-buttons button, button.button-go {
	display:flex;
	flex-direction:column;
}

.add-player-buttons .button-mixed svg {
    margin-right: 0;
}

.roster-locked .button-go, .roster-locked .button-randomize, .roster-locked .button-save, .roster-locked .button-preferences {
	display:none;
}

.roster-button-text {
	margin-left:0.3em;
}

.controlbox .roster-button-text {
	margin-left:0;
}

.netStatValue {
	position: absolute;
    right: 15%;
    font-size: 70%;
    font-weight: bold;
	pointer-events: none;
}

.roster-altboard .netStatValue {
	right: 0;
	padding-right: 3%;
	padding-left: 1%;
}

.fixed-element-unavailable .netStatValue {
	display: none;
}

.netStatValue-positive {
	color: white;
}

.netStatValue-negative {
	color: rgb(194 0 0 / 60%);
}

.netStatValue-positive.cell-position-- {
    background-color: #D0D0D0;
}

.roster-altboard .netStatValue-positive.cell-position-- {
    background-color: transparent;
	color: #666;
}

.roster-container:not(.roster-altboard) .netStatValue-positive.cell-position-- {
    padding: 0 0.2em;
    right: 12%;
}

.undergrid-netstats {
	background-color: #F6F6F6;
}

.roster-unlocked .undergrid-netstats {
	margin-top: 0.5em;
}

.undergrid .grid {
	align-items: center;
}

.undergrid .cell {
	height: auto;
	font-weight: bold !important;
}

.cell-netstat {
	color: #65b965;
}

.cell-netstat-negative {
	color: #fe6a6a;
}

.cell-netstat-equal {
	color: #888;
}

.cell-position.cell-netstat svg {
	font-size: 150%;
	margin-right: 0.4em;
}

.roster-warning {
	color: #e4a228;
	text-align: center;
	margin: 1em;
}

.roster-warning span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.roster-warning svg {
	font-size: 125%;
	margin-right: 0.25em;
}

.roster-warning button svg {
	font-size: 200%;
}

@media only screen and (min-width: 620px)  {
	.cell-position svg {
		font-size:150%;
	}
}

@media only screen and (min-width: 900px)  {
	.cell-position {
		min-width:136px;
	}

	.cell-position svg {
		font-size:200%;
	}

	.roster-altboard .cell-position {
		min-width: 180px;
	}
}

@media only screen and (max-width: 800px) {
	.netStatValue {
		top: 0;
		right: 0 !important;
		background-color: transparent;
		font-size: 60%;
		width: 100%;
		padding: 0 !important;
	}

	.roster-altboard .cell span, .roster-altboard .undergrid .cell {
		font-size: 90%;
	}
}

@media only screen and (max-width:466px) {
	.button-print .roster-button-text,
	.button-toggleview .roster-button-text,
	.button-help .roster-button-text,
	.add-player-buttons .add-player-button .roster-button-text
	{
		display:none;
	}
	.add-player-buttons .add-player-button {
		margin: 0 calc(9vw - 1em);
	}
	.roster-altboard .cell span, .roster-altboard .undergrid .cell {
		font-size: 80%;
	}
}

@media only screen and (max-width:700px) {
	.column-preferences .cell-position-normal {
		min-width: 3em;
	}
	.stats .summaryline {
	    grid-template-columns: 1fr 9% 9% 9% 9% 9% 9% 9% 10%;
	}
}

@media only screen and (max-width:540px) {
	.column-preferences .cell-position-normal {
		min-width: 2em;
	}
}

@media only screen and (max-width:400px) {
	.button-save .roster-button-text,
	.button-randomize .roster-button-text
	{
		display:none;
	}
}

@media only screen and (max-width:350px) {
	.button-delete .roster-button-text,
	.button-preferences .roster-button-text,
	.button-remove-players .roster-button-text
   	{
		display:none;
	}
}

@media only print {

	.button-menu,
	.button-edit,
	.button-delete,
	.button-go,
	.button-share,
	.controlbox,
	.settings,
	.funfact,
	.scorebox,
	.add-player-buttons,
	footer,
	.cell-player .iconbox,
	.cell-position .iconbox {
		display:none !important;
	}

	.cell {
		height:7vh;
	}

	.cell-void, .cell-header {
		height: 3vh;
	}

	.cell-header {
		font-weight:normal;
	}

	.cell-position {
		font-size:200%;
		background-color:white;
		color:black;
		font-weight:400;
		box-shadow:none;
		min-width:136px;
	}

	.cell-player {
		font-size:160%;
		box-shadow:none;
		background-color:inherit;
	}

	.cell-player, .column-positions .posed-element, .column-positions .fixed-element {
		padding:0;
	}

	.cell-player, .cell-position {
		border: 1px solid #CCC;
	}

	.cell-position-unavailable, .cell-player-unavailable {
		opacity: 0.7;
	}

	.fixed-element {
		padding:0;
	}

	.roster-altboard .cell-position {
		font-size:150%;
		height: 2.2em;
	}

	.roster-altboard .column-players .cell {
		border-color: transparent;
		margin-right: 0.6em;
	}

	.roster-altboard .column-players .cell-position-- {
		display:none;
	}

	.roster-altboard .cell-position-- {
		border-color: transparent;
		height: auto;
		line-height: 1.0em;
		margin-top: 0.6em;
	}


	#board .netStatValue {
		display:none;
	}

	.undergrid {
		display: none;
	}

	.underneath-grid {
		margin: 0;
	}

	@page {
		/* I would like to leave this on 'auto', but then Chrome forces Portrait mode! :( */
		size: landscape;
	}

}

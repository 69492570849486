html, body, button {
  font-family: "Heebo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
	position:relative;
	max-width:900px;
	margin:0 auto;
}

/* Using focus-outline-manager */
html.focus-outline-hidden *:focus {
    outline: none;
}

h2 {
	margin-top:0;
}

main.loading {
	display: none;
}

.header-topline {
	display:flex;
	justify-content:space-between;
	align-items:flex-start;
}

.header-title {
	font-size:200%;
	font-weight:bold;
	color:#222;
	margin:0;
	text-decoration:none;
	display:flex;
}

.header-beta:after {
    content: "BETA";
    background: #65b963;
    color: white;
    font-size: 33%;
    padding: 2px 8px;
    margin-left: .9em;
    position: relative;
    top: -0.60em;
}

.netball-icon {
	max-height:1.5em;
	margin:0 0.5em;
	color:#444;
}

.header-userdata {
    display: flex;
	flex-wrap:wrap;
	margin-bottom:1em;
}

.header-game-name:before, .header-game-date:before, .header-game-venue:before, .game .date-time:before {
    content: "\2022";
	margin:0 0.35em;
}

a.header-link-item {
	text-decoration:none;
	color:inherit;
	padding:0.5em 0 1em 0;
	display:inline-flex;
}

.user-box span {
	display: block;
}

.user-box > span:first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom:0.5em;
}

.user-photo {
	max-height:3em;
	border-radius:3em;
	margin:0 0.5em;
}

.user-info {
	text-align:left;
}

.user-name {
	font-size:90%;
}

.user-email {
	color:#666;
	font-size:85%;
}

button, .button {
    border: 0;
    border-radius: 7px;
    display: inline-block;
    padding: 1em 2em;
    transition: background 0.2s;
	color: #444;
	background-color: #EEE;
	cursor:pointer;
	margin-right:0.25em;
	user-select:none;
}

button:disabled {
	opacity:0.25;
	cursor:default;
}

button:not(:disabled):not(.mdl-button):not(.tour-helper):not(.MuiTab-root):hover, .button:hover {
	color:#fff;
	background-color:#444;
}

button.button-animated:hover:focus {
	outline:none;
}

button.enabled {
	background-color:#666;
	color:white;
}

.button-icon {
    padding: 0.5em 1em;
    display: inline-flex;
	align-items:center;
}

.button-icon svg {
    font-size: 200%;
}

.button-mixed svg {
	margin-right:0.5em;
}

.button-menu {
	border-radius:9em;
	padding: 0.5em;
	font-size:100%;
}

a {
	color: green;
}

a.linkish {
    color: green;
    text-decoration: none;
    border-bottom: 1px dotted green;
}

a.linkish:hover {
	border-bottom: 1px solid #65b965;
}

button.linkish {
	background: none;
}

a.button {
	text-decoration: none;
}

.help-navitem {
	font-weight:bold;
	font-size:120%;
	display:inline-block;
}

.help-navitem svg {
    margin-right: 4px;
}

.help-navitem .orange-image {
	height:1.5em;
}

.i-am-signed-in {
	color: green;
}

@media only screen and (max-width: 512px)  {
	.header-topline {
		font-size:85%;
	}

	.textlength-gigantic { font-size: 70%; }
	.textlength-huge { font-size:80%; }
	.textlength-large { font-size: 90%; }
	.textlength-medium { }
	.textlength-small { font-size: 120%; }
	.textlength-tiny { font-size: 140%; }

}

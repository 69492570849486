.menu-overlay .MuiMenuItem-root {
    font-family: inherit;
	letter-spacing: inherit;
}

.menu-overlay a svg, .menu-overlay a img {
	font-size: 180%;
	width:1.5em;
	margin-right:0.25em;
	color:#444;
}

.user-box, .signin-box {
    margin-top: 1em;
    text-align: center;
	width: 100%;
}

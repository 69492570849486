
.list-title {
    display: flex;
}

.list-title-alternates {
    display: flex;
    margin-left: auto;
}

.list-title-alternates a {
	text-decoration:none;
    margin-left:1em;
	color: #999;
	transition: color 0.5s ease-out;
}

.list-title-alternates a:hover {
	color:#000;
	text-decoration:underline;
}

.list-items {
    display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1em;
	margin:0.5em 0;

	padding: 1em;
    background-color: #F9F9F9;
    box-shadow: inset 0 0 6px #D9D9D9;
	min-height:4em;
}

.edit-buttons {
    display: flex;
    justify-content: space-evenly;
}

.editable-item {
	display:block;
}

.editable-item .add-item-field {
	display:block;
    margin: 1em 0;
}

.edit-mode button, .button-delete button {
	background-color: transparent;
}

.edit-mode svg, .button-delete svg {
	font-size: 180%;
}

.edit-mode button:not(:hover) svg, .button-delete button:not(:hover) svg {
    color: #666;
}

.button-delete button:hover {
	background-color: #ff7a7a !important;
}

.edit-local {
	z-index: 10;
    position: absolute;
    right: 0.5em;
    top: 0.5em;
}

.edit-local button {
	font-size:70%;
}

.react-datetime-picker__wrapper {
	flex-wrap: wrap;
	max-width: 100%;
}

@media only screen and (max-width: 620px)  {
	.list-items {
		grid-template-columns: 1fr;
	}
}

.list-items a {
    text-decoration: none;
    color: #000;
}

.list-item {
	display:flex;
}

.item {
	display:block;
	position:relative;
	background:white;
	border-radius: 3px;
    box-shadow: 1px 1px 3px #CCC;
	padding:1em;
	width:100%;
	cursor:pointer;
}

.item:hover {
	box-shadow:1px 1px 3px #777;
}

.item.game {
	padding-bottom: 0.7em;
}

.filter-buttons {
	margin:1em 0;
}

.no-items a {
	margin-left: 0.8em;
	font-size:85%;
}

.editable-item button {
    padding: 0.25em 1em;
    margin-left: 1em;
}

.button-delete-item {
    color: white;
	background: #fd7171;
}

.button-delete-item-caps {
	text-transform: uppercase;
}

.button-update-item {
	font-weight: 600;
}

.button-cancel {
	background: none;
}

button.button-cancel:hover {
    color: inherit !important;
    background-color: inherit !important;
}

button.button-delete-item:hover {
    background-color: #ff4040 !important;
}

.underneath-list-add-edit {
	display: flex;
	justify-content: flex-end;
}

.item-menu {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0.5em;
    color: #999;
}

.item-menu button {
	background-color: inherit;
	color: inherit;
}

.item-menu-overlay .item-menu-item {
	line-height: 2.2em;
}

.item-menu-overlay svg, .button-update-item svg {
    font-size: 120%;
    width: 1.5em;
    margin-right: 0.25em;
}

.item-menu-overlay .item-menu-delete {
	color: #ff2e2e;
}

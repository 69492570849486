.funfact {
	background: #F0F0F0;
    padding: 1em 2em;
    border-radius: 3px;
    font-size: 80%;
	margin: 0 auto;
    max-width: 320px;
    text-align: center;
	position: relative;
	word-break: break-word;
}

.funfact svg {
	font-size: 180%;
    color: #666;
	position: absolute;
	left: 0.25em;
	top: 0.3em;
}

@media only screen and (max-width:760px) {
	.funfact {
		display: none;
	}
}


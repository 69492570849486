.list-item .game {
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

.field-round {
	color:#FFF;
	font-weight:800;
	font-size:80%;
	margin-bottom: 1em;
}

.field-round > div {
    display: inline-block;
    background: #AAA;
    padding: 0 .51em;
}

.field-venue {
	font-size:80%;
	color: #555;
}

.field-result {
	font-size:80%;
	color: #555;
}

.extra-content {
	font-size:90%;
	margin-top:auto;
	padding-top:1em;
}

.extra-content > div {
	min-height:2em;
}

.extra-content svg {
	font-size:140%;
	margin-right:0.2em;
	position:relative;
	top:2px;
}

.roster-yes {
	color:#65b965;
}

.date-past {
	color:#999;
}

.roster-no, .field-absent {
	color:#999;
}

.field-absent svg {
	color:#CCC;
}

.field-orangeman {
	display: flex;
	align-items: center;
}

.field-orangeman .orange-image {
	width: 1.6em;
	margin-right: 0em;
    position: relative;
    left: -2px;
    top: -3px;
}

.field-orangeman-illegal {
	color: red;
}

.field-created {
	color:#999;
	font-size:90%;
	margin-top:1em;
}

.game-current {
	font-size: 150%;
	background-color: #77bf75;
	color: white;
    padding: 0 0.2em;
    border-radius: 0 3px 0 0;
	position: absolute;
	top: 0;
    right: 0;
	display: flex;
    flex-direction: column;
    align-items: center;
}

.game-start {
    font-size: 50%;
    font-weight: normal;
}

.video-yt {
	text-align:center;
	position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
	margin: 1em 0;
	background: linear-gradient(45deg, #CCC, transparent);
	box-shadow: 0 0 1em #888 inset;
}

.video-yt iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.help-scores-table td, .help-scores-table th {
	text-align: center;
	border: 1px solid #444;
	padding: 0.1em 0.4em;
}


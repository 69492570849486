.stats-header-text {
	color: #ff1e8a;
}

.summaryline {
    display: grid;
	grid-template-columns: 1fr 8% 8% 8% 8% 8% 8% 8% 10%;
	text-align:center;
}

.summaryline-header .summaryline-header-text {
	background: none;
	box-shadow: none;
    color: black;
    font-weight: normal;
}

.summaryline-header-text img {
	height:2em;
}

.summaryline:not(.summaryline-header) > div, .summaryline:not(.summaryline-header) > a {
	padding: 1.5vh 0;
}

.summaryline .cell-position {
    min-width: auto;
    cursor: initial;
	margin:0.2em;
}

.orangeman-games, .absent-games {
	grid-column: 3 / -1;
	text-align:left;
}

.stats .summaryline > a {
	text-decoration:none;
	color:inherit;
}

.stats .summaryline > a:hover {
	box-shadow:1px 1px 3px #CCC;
}

.stats-options {
	margin:1em 0;
}

.stats-options a:last-of-type {
    margin-right: 1em;
}

.stats-options label {
	display:inline-block;
	margin:1em 0;
	margin-right: 1em;
}

.stats-options img {
	height:1em;
}

.stats-options img, .stats-options svg {
	margin:0 0.25em;
	transform: scale(1.5);
}

.stats-options-net svg {
	color: #65b965;
	transform: scale(2);
}

.stats-options .disabled {
	opacity: 0.5;
	color: gray;
}

.bestqtr-buttons {
	display: inline-block;
}

.netstats-best-button {
	padding: 0.5em 1em;
    border-radius: 0;
}

.stats-best-qtrs .stats-best-qtrs-list > a > div {
	padding: 0.5vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.stats-best-qtrs a {
	text-decoration: none;
	color: inherit;
}

.stats-best-qtrs .cell {
	font-weight: normal;
	opacity: 0.85;
	overflow: hidden;
}

.bestqtr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bestqtr-round {
	display: flex;
}

.bestqtr-round-round {
    background: #AAA;
    padding: 0 0.51em;
    color: white;
    font-weight: bold;
    display: flex;
}

.bestqtr-round-qtr {
    margin: 0 0.67em;
    font-weight: 600;
}

.bestqtr-opponent {
	font-size: 90%;
	color: #555;
}

.info {
    color: #777;
    font-size: 95%;
}

@media only screen and (max-width: 800px) {
	.netStat-cell {
		font-size: 75%;
	}
	.bestqtr {
		font-size: 75%;
	}
	.bestqtr-round-round {
		font-size: 90%;
	}
	.stats-best-qtrs-list .cell-position {
		font-size: 55%;
	}
}

.enter-scores-container {
	margin-top: 1em;
	background: #f9f9f9;
	padding: 1em 0;
	border-radius: 1em;
}

.enter-scores {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

.enter-scores .edit-scores-title {
	display: none;
}

.enter-scores .enter-results-table {
	margin: 0;
}

.enter-scores .enter-results-table td:nth-child(n+2) {
	background: #fdfdfd;
}

.enter-scores .edit-buttons {
	display: block;
	margin-top: 1em;
}

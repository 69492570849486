.trials-am-busy {
    position: absolute;
    background-color: rgb(82, 175, 117);
    color: white;
    top: 12vh;
    width: 90%;
    text-align: center;
    padding: 3em 0;
    z-index: 10;
    border-radius: 5px;
    left: 5%;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.3), 1px 1px 18px rgba(0,0,0,0.5);
}

.trial-tabs {
	margin: 1em 0;
	flex-grow: 1;
    max-width: 500;
}

.trials-grid {
	padding: 1em 0;
}

.trials-grid .column-players {
    flex-grow: 1;
    margin-left: 1vw;
}

.trials-grid .cell-player {
	cursor: default;
	line-height: 100%;
}

.trials-grid .column-players .cross {
	color: #999;
}

.trials-grid .column-players .iconbox-hitbox:hover .cross {
	color: #C00;
}

.section-trials .MuiTab-root, .section-trials .MuiTypography-body1 {
	font-family:inherit;
}

.section-trials .MuiFormLabel-root.Mui-focused {
	color: green;
}

.section-trials .MuiInput-underline:after {
    border-bottom: 2px solid #65b964;
}

.trial-tabs .MuiTab-textColorPrimary {
	color: #333;
}

.trial-tabs .MuiTab-textColorPrimary.Mui-selected {
    color: green !important;
}

.trial-tabs .MuiTabs-indicator {
	background-color: #65b964;
	height:4px;
}

.trial-tabs .MuiTouchRipple-child {
    background-color: #65b964;
}

.trial-tabs .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
	font-size:180%;
	margin-bottom: 0;
}

.trial-roster-tabs {
	box-shadow: none !important;
	margin: 1em 0;
}

.trial-roster-tabs .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
	font-size:150%;
	margin-bottom: 0;
}

.trial-roster-tabs .MuiTab-textColorPrimary.Mui-selected {
    color: #0079c9 !important;
}

.trial-roster-tabs .MuiTabs-indicator {
	background-color: #60ade0;
	height:4px;
}

.trial-roster-tabs .MuiTouchRipple-child {
    background-color: #60ade0;
}

.trial-roster-tabs .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
	font-size:180%;
	margin-bottom: 0;
}

.trials-form-switches .MuiSwitch-colorPrimary.Mui-checked {
	color: #65b964;
}

.trials-form-switches .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: #65b964;
}

.section-trials .MuiBox-root {
	padding: 0 !important;
}

.trials-modal-import-players .Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #65b964 !important;
}

.trials-modal-import-players .MuiFormLabel-root.Mui-focused {
	color: green;
}

.trials-roster-games {
    display: grid;
	grid-template-columns: 1fr 1fr;
}

.trials-roster-game {
	box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
	padding: 0.2em;
    margin: 0.2em;
}

.trials-roster-game-title {
    text-align: center;
    font-weight: 800;
}

.trials-roster-game-line {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.trials-roster-game-line .cell-position {
	min-width: 4em;
}

.trials-games .cell {
	height: 1.8em;
	margin: 2px;
}

.trials-player-roster .fixed-element {
    padding: 0;
}

.column-preferences {
    position: relative;
}

.cell-header.num-preferences {
    position: absolute;
    top: -1em;
    font-weight: 100;
    font-size: 80%;
	width: 100%;
	text-align: center;
}

h2.trials-roster-round-title {
    background-color: #eeeeee;
    margin: 1em 0;
    padding: 1em;
    font-size: 75%;
    color: #222;
    font-weight: 400;
    text-align: center;
    letter-spacing: 4px;
}

.trials-roster-court-number {
    font-size: 75%;
	color: #222;
}

.trials-player-roster .column-players {
	flex-grow: 10;
}

.trials-player-roster .column-preferences {
	flex-grow: 1;
}

.trials-player-roster .column-preferences .cell-position-normal {
	min-width:0;
}

.trials-roster-games .trials-roster-game-player {
	font-size: 90%;
	padding: 0 2px;
	overflow: visible;
	line-height: 1;
}

.trials-form {
	display: flex;
	align-items: flex-start;
}

.trials-form-players {
	justify-content: space-between;
}

.trials-form-players .button-import svg {
    font-size: 150%;
    margin-right: 0.25em;
	color:#666;
}

.trials-exporter .button-download {
	font-size: 100%;
}

.trials-exporter .button-download svg {
	margin-right: 0.3em;
}

.trials-form-courts .MuiTextField-root {
    margin-right: 2em;
}

.trials-form .trials-form-switches {
	margin-left: auto;
	flex-direction: row;
	color: #00000080;
}

.trials-form-switches .MuiTypography-body1 {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 0.73rem;
    line-height: 1;
}

.trials-modal-content {
	background: white;
    margin: 10vh 5vw 0 5vw;
    padding: 1em;
	max-height: 75vh;
	overflow: scroll;
}

.trials-modal-import-players textarea {
	font-size: 90%;
}

.info-box {
	display: flex;
	margin: 1em 0;
	font-size: 90%;
}

.info-box svg {
	font-size: 150%;
    margin: 0 0.5em;
    flex-shrink: 0;
    background: #65b964;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.info-box .cell-player, .info-box .cell-position {
	display: inline-flex;
	justify-content: center;
    align-items: center;
	min-width: 4em;
	padding: 0 1vw;
	min-width: initial;
}

.trials-outdated-roster {
    background: #ff86c020;
    padding: 7px 0;
    color: #cc0a67;
}

.trials-outdated-roster svg {
	background-color: #e38edf;
}

.trials-repeating-players svg {
	background-color: #60ade0;
}

.trials-empty-slots svg {
	background-color: #ab9dec;
}

.trials-consecutive-games svg {
	background-color: #ff86c0;
}

.trials-fill-ins svg {
	background-color: #00bac0;
}

.trials-extra-roster-info {
	margin-top:2em;
	padding-top:1em;
	border-top: 2px dotted #EEE;
}

.trials-games .cell-header-round {
    background: #EEE;
    margin-left: 0;
    margin-right: 0;
    color: transparent;
}

.trials-games .header-round-first-game {
    margin-left: 2px;
    color: #222;
}

.trials-player-schedule {
	display: grid;
	grid-template-columns: auto 1fr;
	margin-top: 1em;
    border-top: 1px solid #CCC;
    padding-top: 1em;
}

.trials-player-schedule-name {
	margin-top: 2px;
	padding-right: 1em;
	max-width: 10em;
}

.trials-player-schedule-name, .trials-player-schedule-games {
	margin-bottom: 1em;
	padding-bottom: 1em;
	border-bottom: 1px solid #CCC;
}

.trials-player-schedule .cell-position {
    display: inline-block;
	min-width: 4em;
	text-align: center;
    margin: 2px;
}

.trials-player-schedule-games span {
	margin: 0 0.5em;
}

.trials-player-schedule-round, .trials-player-schedule-court {
	font-weight: 100;
}

.trials-player-schedule-round, .trials-player-schedule-court, .trials-player-schedule-game {
    font-size: 90%;
    min-width: 5em;
    display: inline-block;
}

.trials-roster-output {
	transition: opacity 0.25s ease-out;
}

.trials-roster-output-unready {
	opacity: 0;
}

.trials-roster-stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	margin: 1em 0;
}

.trials-roster-stats div {
    background: #eeeeee;
    padding: 0.5em;
    border-radius: 5px;
	color: #333;
	margin: 0.2em 0.8em;
}

.trials-roster-stats div span {
    background: white;
	margin-left: 0.3em;
    padding: 0 0.5em;
    border-radius: 3px;
    font-weight: bold;
    font-size: 130%;
	box-shadow: 1px 1px 3px #999;

    min-width: 1.2em;
    display: inline-block;
    text-align: center;
}

.explain-block {
	display: block;
}

.GrowInBox {
	overflow: hidden;
}

@media only screen and (max-width:900px) {
	.trials-grid .cell-player,
	.trials-grid .column-positions .posed-element,
	.trials-grid .fixed-element {
		padding-left: 1px;
		padding-right: 1px;
	}
	.trials-roster-games {
		grid-template-columns: 1fr;
		margin: 0 100px;
	}
}

@media only screen and (max-width:640px) {
	.trials-roster-games {
		margin: 0;
	}
	.trials-player-schedule {
		grid-template-columns: 1fr auto;
	}
	.trials-player-schedule-games span {
		font-size: 80%;
	}
}


@media only screen and (max-width:410px) {
	.trials-grid .cell-player,
	.trials-grid .column-positions .posed-element,
	.trials-grid .fixed-element {
		padding-left: 0;
		padding-right: 0;
	}

	.trials-grid .column-preferences .cell-position-normal {
		min-width: 1.5em;
	}

	.trials-form {
		flex-wrap: wrap;
	}
	.trials-form-courts .MuiTextField-root {
		margin-right: 2px;
	}
	.trials-form .trials-form-switches {
		width: 100%;
		display:block;
	}
	.trials-form .trials-form-switches label {
		margin:1em 1em 1em 0;
	}
}

@media only print {

	#root {
		max-width:initial;
	}

	.trials-intro-text,
	.trial-tabs,
	.trial-roster-tabs,
	.trials-form,
	.trials-explain-constraints,
	.MuiLinearProgress-root
	{
		display:none;
	}

	.section-trials .cell-position, .section-trials .cell-player {
		font-size: 100%;
	}

	.section-trials .cell-player {
		border: 1px solid transparent;
	}

	h2.trials-roster-round-title {
		font-weight: 800;
	}
}

.error-boundary {
    margin-top: 10vh;
}

.error-title {
    font-size: 180%;
    margin: 0.5em 0;
	display: flex;
    align-items: center;
}

.let-max-know {
	background-color: #F0F0F0;
	margin: 0.5em 0;
	padding: 1.5em;
	border-radius: 0.5em;
}

.error-subtitle {
	color: red;
	margin-right: 0.5em;
}

.error-boundary fieldset {
	font-size: 90%;
	color: #444;
	border-color: red;
	border-radius: 0.2em;
	margin: 1em 0;
	word-break: break-word;
}

.error-boundary legend {
	color: red;
	font-size: 85%;
}

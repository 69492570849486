.add-item {
	display:flex;
	justify-content:flex-end;
	flex-wrap:wrap;
}

.additemform {
	display:flex;
	flex-wrap: wrap;
}

.add-item-details {
	flex-grow:1;
	background-color: #FAFAFA;
	padding: 1em 0.25em;
    margin: 1em 0 2em 0;
    box-shadow: 1px 1px 3px #CCC, 1px 1px 12px #CCC;
}

.add-item-field:not(.react-datetime-picker) {
	flex-grow: 1;
	margin: 0.5em;
	padding: 0.5em;
	font-size: 115%;
}

.enter-results-table {
	margin: 1em 0;
}

.enter-results-table {
	color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.enter-results-table th {
	font-weight: 400;
}

.enter-results-table td {
	text-align: center;
}
